<template>
  <Dialog visible="isVisible" :style="{width: '450px'}" header="Category Details" :modal="true" class="p-fluid">
    <div class="field">
      <label for="description">Description</label>
      <InputText id="description" v-model.trim="category.description" required="true" :class="{'p-invalid': categorySubmitted && !category.description}" />
    </div>
    <div class="field">
      <label for="Type">Type</label>
      <Dropdown v-model="category.type" :options="typeList" optionLabel="type" optionValue="id" placeholder="Select" required="true" :editable="false" :class="{'p-invalid': categorySubmitted && !category.type}" />
    </div>
    <div class="field">
      <p class="mb-1">Exclude</p>
        <InputSwitch v-model="category.exclude" />
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="cancel"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveCategory" />
    </template>
  </Dialog>
</template>

<script>
  import CategoryService from '../service/CategoryService'

  export default {
    props: {
      isVisible: { default: false, type: Boolean },
      category_id: { type: Number }
    },
    emits: ['refresh', 'visible'],
    data() {
      return {
        category: {},
        categorySubmitted: false,
        typeList: [
          {'id': 'E', 'type': 'Expense'},
          {'id': 'I', 'type': 'Income'}
        ]
      }
    },
    created() {
      this.categoryService = new CategoryService()
    },
    mounted() {
      if (this.category_id) {
        this.categoryService.getCategory(this.category_id).then(data => this.category = data)
      } else {
        this.category = {
          description: '',
          exclude: false
        }
      }
    },
    methods: {
      cancel() {
        this.$emit('visible', false)
      },
      saveCategory() {
        this.categorySubmitted = true
        if (this.category.description.trim()) {
          if (this.category.id) {
            this.categoryService.editCategory(this.category).then(data => {
              this.categoryService.getCategories().then(data => this.categoryList = data)
              this.$emit('refresh')
              this.$emit('visible', false)
              console.log(data)
            })
          } else {
            this.categoryService.addCategory(this.category).then(data => {
              this.categoryService.getCategories().then(data => this.categoryList = data)
              this.$emit('refresh')
              this.$emit('visible', false)
              console.log(data)
            })
          }
        }
      }
    }
  }
</script>
