<template>
  <Sidebar visible="isVisible" :baseZIndex="10000" position="right">
    <span class="block text-600 font-medium mb-3">Transaction History</span>
    <Timeline :value="transactionHistory" align="alternate">
        <template #content="slotProps">
            <span class="text-900">{{ slotProps.item.description }}</span><br>
            <span class="text-700">{{ formatDate(slotProps.item.date) }}</span><br>
            <span class="text-500">{{ slotProps.item.account.type }}</span>
        </template>
    </Timeline>
  </Sidebar>
</template>

<script>
  import TransactionService from '../service/TransactionService'

  export default {
    props: {
      isVisible: { default: false, type: Boolean }
    },
    data() {
      return {
        transactionHistory: []
      }
    },
    created() {
      this.transactionService = new TransactionService()
    },
    mounted() {
      this.transactionService.getTransactionsHistory().then(data => this.transactionHistory = data)
    },
    methods: {
      formatDate(value) {
        const newDate = new Date(value)
        return newDate.toLocaleDateString('en-ca')
      }
    }
  }
</script>
