import axios from 'axios'
import authHeader from './AuthHeader';

export default class EstimateService {

    addEstimate(newEstimate) {
        return axios.post(process.env.VUE_APP_BASEURL + '/estimates', newEstimate, { headers: authHeader() }).then(res => res.data);
    }

    getEstimate(estimate_id) {
        return axios.get(process.env.VUE_APP_BASEURL + '/estimates/' + estimate_id, { headers: authHeader() }).then(res => res.data);
    }

    editEstimate(estimate) {
        return axios.put(process.env.VUE_APP_BASEURL + '/estimates/' + estimate.id, estimate, { headers: authHeader() }).then(res => res.data);
    }
}
