<template>
  <Dialog visible="isVisible" :style="{width: '450px'}" header="Estimate Details" :modal="true" class="p-fluid">
    <div class="field">
      <label for="date">Category</label>
      <InputText id="description" v-model.trim="estimate.description" disabled/>
    </div>
    <div class="field">
      <label for="total">Total</label>
      <InputText id="total" v-model="estimate.total" required="true" :class="{'p-invalid': estimateSubmitted && !estimate.total}" />
    </div>
    <div class="field">
      <label for="total">Notes</label>
      <Textarea placeholder="Notes" v-model="estimate.notes" :autoResize="true" rows="3" />
    </div>
    <template #footer>
      <Button label="Copy Prev" icon="pi pi-copy" class="p-button-text" @click="copyEstimate"/>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="cancel"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveEstimate" />
    </template>
  </Dialog>
</template>

<script>
  import EstimateService from '../service/EstimateService'
  import CategoryService from '../service/CategoryService'

  export default {
    props: {
      isVisible: { default: false, type: Boolean },
      category_id: { type: Number },
      date_range: { type: Date }
    },
    emits: ['refresh', 'visible'],
    data() {
      return {
        estimate: {},
        estimateSubmitted: false
      }
    },
    created() {
      this.estimateService = new EstimateService()
      this.categoryService = new CategoryService()
    },
    mounted() {
      const y = this.date_range.getFullYear(), m = this.date_range.getMonth()
      const firstDay = new Date(y, m, 1)
      const lastDay = new Date(y, m + 1, 0)
      this.categoryService.getCategoryEstimate(this.category_id, this.formatDate(firstDay), this.formatDate(lastDay)).then(data => {
        if (data != 'Category not found') {
          this.estimate.id = data.id
          this.estimate.description = data.category.description
          this.estimate.total = data.total
          this.estimate.notes = data.notes
          this.estimate.date = this.formatDate(this.date_range)
          this.estimate.category_id = data.category.id
        } else {
          this.categoryService.getCategory(this.category_id).then(data => {
            this.estimate.description = data.description
            this.estimate.total = 0
            this.estimate.notes = ''
            this.estimate.date = this.formatDate(this.date_range)
            this.estimate.category_id = this.category_id
          })
        }
      })
    },
    methods: {
      cancel() {
        this.$emit('visible', false)
      },
      formatDate(value) {
        const newDate = new Date(value)
        return newDate.toLocaleDateString('en-ca', {timeZone: 'UTC'})
      },
      saveEstimate() {
        this.estimateSubmitted = true
        if (this.estimate.id) {
          this.estimateService.editEstimate(this.estimate).then(data => {
            this.$emit('refresh')
            this.$emit('visible', false)
            console.log(data)
          })
        } else {
          this.estimateService.addEstimate(this.estimate).then(data => {
            this.$emit('refresh')
            this.$emit('visible', false)
            console.log(data)
          })
        }
      },
      copyEstimate() {
        console.log(this.date_range)
        const y = this.date_range.getFullYear(), m = this.date_range.getMonth()
        const firstDay = new Date(y, m - 1, 1)
        const lastDay = new Date(y, m - 1, 30)
        console.log(this.formatDate(firstDay) + '-' + this.formatDate(lastDay))
        // Get the previous month, run getCategoryEstimate on it and pull the last months estimate
        // If no estimate for last month, need to do manually.
        this.categoryService.getCategoryEstimate(this.category_id, this.formatDate(firstDay), this.formatDate(lastDay)).then(data => {
        if (data != 'Category not found') {
          this.estimate.description = data.category.description
          this.estimate.total = data.total
          this.estimate.notes = data.notes
          this.estimate.date = this.formatDate(this.date_range)
          this.estimate.category_id = data.category.id
        } else {
          this.categoryService.getCategory(this.category_id).then(data => {
            this.estimate.description = data.description
            this.estimate.total = 0
            this.estimate.notes = ''
            this.estimate.date = this.formatDate(this.date_range)
            this.estimate.category_id = this.category_id
          })
        }
      })

      }
    }
  }
</script>
