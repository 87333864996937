<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-6">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Expenses</span>
            <div class="text-900 font-medium text-xl">{{ formatCurrency(calculateMonthlyEstimate('E')) }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-compass text-orange-500 text-xl"></i>
          </div>
        </div>
        <span class="text-orange-500 font-medium">estimate</span>
        <div class="flex justify-content-between mt-3">
          <div>
            <div class="text-900 font-medium text-xl">{{ formatCurrency(calculateMonthlyActual('E')) }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-compass text-orange-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">actual</span>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-6">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Income</span>
            <div class="text-900 font-medium text-xl">{{ formatCurrency(calculateMonthlyEstimate('I')) }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-money-bill text-green-500 text-xl"></i>
          </div>
        </div>
        <!-- <span class="text-green-500 font-medium">{{ formatCurrency(calculateMonthlyEstimate() - calculateMonthlyActual()) }}&nbsp;</span> -->
        <span class="text-orange-500 font-medium">estimate</span>
        <div class="flex justify-content-between mt-3">
          <div>
            <div class="text-900 font-medium text-xl">{{ formatCurrency(calculateMonthlyActual('I')) }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-money-bill text-green-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">actual</span>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="mb-3">
          <Toolbar>
            <template v-slot:left>
              <Button icon="pi pi-arrow-left" class="p-button-rounded p-button-text" @click="goToMonth('prev')"/>&nbsp;
              <Calendar id="dateRange" :showIcon="true" v-model="date_range" view="month" dateFormat="MM yy" @hide="getTransactions()"></Calendar>&nbsp;
              <Button icon="pi pi-arrow-right" class="p-button-rounded p-button-text" @click="goToMonth('next')"/>
            </template>
            <template v-slot:right>
              <Button icon="pi pi-plus" label="Add Transaction" class="mr-2 p-button-success bg-green-100 text-green-500" @click="newTransaction" />
              <Button icon="pi pi-plus" label="Add Category" class="mr-2 p-button-info text-blue-500 bg-blue-100" @click="newCategory" />
              <Button icon="pi pi-wallet" label="Show History" class="mr-2 p-button-help text-purple-500 bg-purple-100" @click="visibleRight = true" />
            </template>
          </Toolbar>
        </div>
        <DataTable :value="transactionsByCategory" v-model:expandedRows="expandedRows" dataKey="id" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="description" header="Category">
            <template #body="slotProps">
              {{ slotProps.data.description }}
              <Tag icon="pi pi-exclamation-triangle text-xs" severity="warning" value="Excluded" v-if="slotProps.data.exclude"></Tag>
              <Tag icon="pi pi-money-bill text-xs" severity="success" value="Income" v-if="slotProps.data.type=='I'"></Tag>
            </template>
          </Column>
          <Column field="estimate" header="Estimate">
            <template #body="slotProps">
                <span @click="editEstimate(slotProps.data.id)">{{ formatCurrency(getCategoryEstimate(slotProps.data)) }}</span>
            </template>
          </Column>
          <Column field="actual" header="Actual">
            <template #body="slotProps">
              <div :class="{ 'text-pink-500': calculateCategoryActual(slotProps.data.transactions) > getCategoryEstimate(slotProps.data) }">
                {{ formatCurrency(calculateCategoryActual(slotProps.data.transactions)) }}
              </div>
            </template>
          </Column>
          <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-text mr-2 p-button-secondary p-button-sm" @click="editCategory(slotProps.data.id)" />
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="transactions-subtable">
              <DataTable :value="slotProps.data.transactions" responsiveLayout="scroll" class="p-datatable-sm">
                <Column field="description" header="Description" sortable>
                  <template #body="slotProps" sortable>
                    <span :class="{ 'text-gray-400': !slotProps.data.posted }">{{ slotProps.data.description }}</span>
                  </template>
                </Column>
                <Column field="date" header="Date" sortable>
                  <template #body="slotProps" sortable>
                    <span :class="{ 'text-gray-400': !slotProps.data.posted }">{{ formatDate(slotProps.data.date) }}</span>
                  </template>
                </Column>
                <Column field="cost" header="Cost" sortable>
                    <template #body="slotProps" sortable>
                      <span :class="{ 'text-gray-400': !slotProps.data.posted }">{{formatCurrency(slotProps.data.cost)}}</span>
                    </template>
                </Column>
                <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
                <Column>
                  <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-text mr-2 p-button-secondary p-button-sm" @click="editTransaction(slotProps.data.id)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-text mr-2 p-button-danger p-button-sm" @click="confirmDeleteTransaction(slotProps.data.id)" />
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>

  <transaction-modal v-if="transactionDialog" v-model:visible="transactionDialog" @visible="updateTransactionDialog" @refresh="getTransactions" :transaction_id="transaction_id"></transaction-modal>
  <category-modal v-if="categoryDialog" v-model:visible="categoryDialog" @visible="updateCategoryDialog" @refresh="getTransactions" :category_id="category_id"></category-modal>
  <history-panel v-if="visibleRight" v-model:visible="visibleRight"></history-panel>
  <estimate-modal v-if="estimateDialog" v-model:visible="estimateDialog" @visible="updateEstimateDialog" @refresh="getTransactions" :category_id="estimateCategory_id" :date_range="date_range"></estimate-modal>
</template>

<script>
import HistoryPanel from '../components/HistoryPanel.vue'
import CategoryModal from '../components/CategoryModal.vue'
import TransactionModal from '../components/TransactionModal.vue'
import EstimateModal from '../components/EstimateModal.vue'
import TransactionService from '../service/TransactionService'

export default {
  components: {
   HistoryPanel,
   CategoryModal,
   TransactionModal,
   EstimateModal
  },
  data() {
    return {
      transactionsByCategory: [],
      expandedRows: [],
      date_range: "",
      transaction_id: null,
      transactionDialog: false,
      category_id: null,
      categoryDialog: false,
      estimateCategory_id: null,
      estimateDialog: false,
      visibleRight: false
    }
  },
  created() {
    this.transactionService = new TransactionService()
  },
  mounted() {
    const date = new Date(), y = date.getFullYear(), m = date.getMonth()
    const firstDay = new Date(y, m, 1)
    this.date_range = firstDay
    this.getTransactions()
  },
  methods: {
    goToMonth(value) {
      if (value == 'prev') {
        this.date_range = new Date(this.date_range.getFullYear(), this.date_range.getMonth()-1, 1)
      } else if (value == 'next') {
        this.date_range = new Date(this.date_range.getFullYear(), this.date_range.getMonth()+1, 1)
      }
      this.getTransactions()
    },
    getTransactions() {
      const y = this.date_range.getFullYear(), m = this.date_range.getMonth()
      const firstDay = new Date(y, m, 1)
      const lastDay = new Date(y, m + 1, 0)
      this.transactionService.getTransationsByCategory(this.formatDate(firstDay), this.formatDate(lastDay)).then(data => this.transactionsByCategory = data)
    },
    formatCurrency(value) {
      // return value
      return value.toLocaleString('en-CA', {style: 'currency', currency: 'CAD'})
    },
    formatDate(value) {
      const newDate = new Date(value)
      return newDate.toLocaleDateString('en-ca', {timeZone: 'UTC'})
    },
    getCategoryEstimate(data) {
      let total = 0

      if (data.estimates.length)
        total = data.estimates[0].total

      return total

    },
    calculateCategoryActual(transactions) {
      let total = 0
      for (let transaction of transactions) {
        total += transaction.cost
      }

      return total;
    },
    calculateMonthlyActual(type) {
      let total = 0

      if (this.transactionsByCategory) {
        for (let category of this.transactionsByCategory) {
          if (category.type == type && category.exclude == false) {
            for (let transaction of category.transactions) {
              if (transaction.posted) {
                total += transaction.cost
              }
            }
          }
        }
      }

      return total
    },
    calculateMonthlyEstimate(type) {
      let total = 0

      if (this.transactionsByCategory) {
        for (let category of this.transactionsByCategory) {
            if (category.estimates.length && category.type == type && category.exclude == false)
              total += category.estimates[0].total
        }
      }

      return total;
    },
    newTransaction() {
      this.transaction_id = null
      this.transactionDialog = true
    },
    updateTransactionDialog(value) {
      this.transactionDialog = value
    },
    editTransaction(transaction_id) {
      this.transaction_id = transaction_id
      this.transactionDialog = true
    },
    newCategory() {
      this.category_id = null
      this.categoryDialog = true
    },
    updateCategoryDialog(value) {
      this.categoryDialog = value
    },
    editCategory(category_id) {
      this.category_id = category_id
      this.categoryDialog = true
    },
    updateEstimateDialog(value) {
      this.estimateDialog = value
    },
    editEstimate(category_id) {
      this.estimateCategory_id = category_id
      this.estimateDialog = true
    },
    deleteTransaction() {

    },
    confirmDeleteTransaction(transaction) {
      console.log("Delete", transaction)
      // this.product = product;
      // this.deleteProductDialog = true;
    }
  },
}
</script>
